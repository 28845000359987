
import Vue from 'vue'
import { mapGetters } from 'vuex'
import socialNetworks from './components/socialNetworks/index.vue'
import safety from './components/safety/index.vue'
import kyc from './components/kyc/index.vue'
import kycStatusCard from './components/kyc-status-card/index.vue'
import accessRepairStatus from '@/components/pages/whitelistID/components/account/components/access-repair/accessRepairStatus/index.vue'
import PassportNotification from '@/components/pages/whitelistID/components/account/components/notifications/index.vue'
import { AccessStatus, IUnifiedAcc, KycStatus } from './components/types'
import { systemPromoCodes } from '@/constants/baseConstatnts'
import { IKycLinkDataResponse, VerificationStatuses } from './types'
import { copyToClipboard } from '@whitelist/wl-old-library/utils/other'

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function

export default Vue.extend({
  name: 'myAccountPage',
  data () {
    return {
      kycDataLoaded: false,
      kycData: {},
      unifiedAcc: [] as IUnifiedAcc[],
      currentKycLevelData: {},
      kycOpen: false,
      accessRepairOpen: false,
      currentYPosition: 0,
      scrollTo: { x: 0, y: 0 },
      accessRepairData: {
        confirmationMethod: '',
        status: '',
        reason: '',
        loginMethod: '',
        email: '',
        currentRecoveryTypes: []
      },
      uuidCopiedTimer: 0
    }
  },
  watch: {
    'getUserData.currentAccessRepairMethods' (value: any) {
      if (value.length > 1) this.kycOpen = true
    }
  },
  computed: {
    ...mapGetters('user', ['getUserData']),
    ...mapGetters('notifications', ['getPassport']),
    isMobile (): boolean {
      return 'ontouchstart' in window
    },
    getUserFullName () {
      const vm = this as any
      return vm.getUserData.last_name + ' ' + vm.getUserData.first_name + ' ' + (vm.getUserData.patronymic ?? '')
    },
    tooltipPromocode () {
      const { promo } = this.getUserData
      return systemPromoCodes.includes(promo) ? this.$t('registration.refSystemCodeExplain') : this.$t('registration.refCodeExplain', { name: promo })
    },
    kycType (): KycStatus | string {
      let result = ''
      const extendedStatus = this.kycData?.extended?.status
      const advancedStatus = this.kycData?.advanced?.status
      switch (extendedStatus) {
        case VerificationStatuses.wait:
          result = KycStatus.loading
          break
        case VerificationStatuses.declined:
          result = KycStatus.failed
          break
        case VerificationStatuses.double:
          result = KycStatus.failedDouble
          break
      }
      if (advancedStatus === VerificationStatuses.addressConflict) {
        result = KycStatus.conflict
      }
      return result
    }
  },
  components: {
    socialNetworks,
    safety,
    kyc,
    accessRepairStatus,
    PassportNotification,
    kycStatusCard
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    const verificationShow = urlParams.get('verification-show') === 'true'
    const autoProceedKyc = urlParams.get('auto-proceed-kyc') === 'true'
    if (autoProceedKyc) this.proceedVerification('auto')
    if (verificationShow) {
      this.kycOpen = true
    }
    const kyc = urlParams.get('kyc') === 'true'
    if (kyc) this.goToAnotherAcc()
  },
  methods: {
    closeFromStatusCard () {
      this.getKycData()
    },
    async getKycData () {
      const kycDataResponse: { success: boolean, data: IKycLinkDataResponse, status: number } = await this.$services.authorization.user.kycData()
      if (!kycDataResponse.success) return
      this.kycData = kycDataResponse.data
      if (this.kycData.advanced.status === VerificationStatuses.addressConflict) {
        const response = await this.$services.authorization.user.getAccountConflict()
        this.$store.dispatch('alertsBus/openAlert', {
          title: this.$t('alerts.titles.attention'),
          type: 'warning',
          autoDelete: true,
          content: this.$t('alerts.content.extendedNotFinished')
        })
        if (response.success) {
          this.unifiedAcc = response.data
        }
      }
      // Mock
      // const kycDataResponse: { success: boolean, data: IKycLinkDataResponse, status: number } = await this.$services.authorization.user.kycData()
      // if (!kycDataResponse.success) return
      // this.kycData = {
      //   basic: {
      //     status: 'accepted',
      //     reason: null
      //   },
      //   extended: {
      //     status: 'notStarted',
      //     reason: null
      //   },
      //   advanced: {
      //     status: 'wait',
      //     reason: null
      //   }
      // }
      // // const kycLinkResponse: { data: { url: string, errors: string[] }, success: boolean } = await this.$services.authorization.user.kycLink()
      // const kycLinkResponse = { success: true, data: { url: 'хуй', errors: ['mlsdf'] } }
      // if (kycLinkResponse.success) this.kycData.link = kycLinkResponse.data.url
      // else {
      //   this.$store.dispatch('alertsBus/openAlert', {
      //     title: this.$t('alerts.titles.error'),
      //     type: 'error',
      //     autoDelete: true,
      //     content: kycLinkResponse.data.errors.join('\n')
      //   })
      // }
      // if (this.kycData.advanced.status === VerificationStatuses.addressConflict) {
      //   const response = await this.$services.authorization.user.getAccountConflict()
      //   this.$store.dispatch('alertsBus/openAlert', {
      //     title: this.$t('alerts.titles.attention'),
      //     type: 'warning',
      //     autoDelete: true,
      //     content: this.$t('alerts.content.extendedNotFinished')
      //   })
      //   if (response.success) {
      //     this.unifiedAcc = response.data
      //   }
      // }
    },
    proceedVerification (initialize = 'proceed') {
      const self = this as any
      if (this.getUserData.verificationRights.levelName === 'basic' || initialize === 'auto') {
        self.$store.dispatch('popupModule/openPopup', {
          type: 'confirmPopup',
          letDefaultClose: true,
          title: self.$t('popups.titles.transferUserData'),
          content: self.$t('popups.content.doYouHaveAcc'),
          actions: [
            {
              title: self.$t('popups.actions.transfer'),
              isAccent: false,
              callback () {
                self.goToAnotherAcc()
              }
            },
            {
              title: self.$t('popups.actions.kycProceed'),
              isAccent: false,
              callback () {
                self.$store.commit('popupModule/setClosed')
                self.agreeDataIsProof()
              }
            }
          ]
        })
        const url = new URL(window.location.href)
        url.searchParams.delete('auto-proceed-kyc')
        window.history.replaceState(null, '', url.toString())
      } else {
        self.agreeDataIsProof()
      }

      // Метки регистрирующие прохождение kyc
      // eslint-disable-next-line no-undef
      gtag('event', 'conversion_event_subscribe_paid')
      window._tmr.push({ type: 'reachGoal', id: 3467065, goal: 'KycVerificationRequest' })
      window.ym(95601145, 'reachGoal', 'KycVerificationRequest', { userId: this.getUserData.uuid })
    },
    goToAnotherAcc () {
      const self = this as any
      self.$store.dispatch('popupModule/openPopup', {
        type: 'action',
        letDefaultClose: true,
        title: self.$t('popups.titles.goToAnotherAcc'),
        content: '',
        slot: {
          name: 'goToAnotherAcc'
        }
      })
    },
    agreeDataIsProof () {
      const self = this as any
      self.$store.dispatch('popupModule/openPopup', {
        type: 'action',
        letDefaultClose: true,
        title: self.$t('popups.titles.agreementDataProof'),
        content: '',
        slot: {
          name: 'agreeDataIsProof'
        }
      })
    },
    scrollToCurrentRepair () {
      this.scrollTo.y += 0.1
      this.accessRepairOpen = true
    },
    changeKycAccordionStatus (value: boolean) {
      const vm = this as any
      vm.scrollTo.y += 0.1
      const scrollToElement: HTMLElement | null = document.getElementById('kyc-extended')
      const nextScrollPosition = Math.abs(scrollToElement?.getBoundingClientRect().top ?? 0)
      vm.kycOpen = value
      if (!value) return
      vm.scrollTo.y = nextScrollPosition
    },
    async getKycLevels () {
      const kycLevelsResponse = await this.$services.authorization.user.kycLevels()
      if (!kycLevelsResponse.success) return
      this.currentKycLevelData = kycLevelsResponse.data.find((level: any) => level.levelName === this.getUserData.verificationRights.levelName)
    },
    async getAccessInfo (created?: boolean) {
      await this.$store.dispatch('user/loadUser')
      this.recoveryId = this.getUserData?.verificationRights?.currentRecovery || this.$route.query.recoveryId
      if (this.$route.query.recoveryId) this.$router.replace({ recoveryId: null })
      if (!this.recoveryId) return
      const getAccessInfo = await this.$services.authorization.user.getAccessInfo({ recoveryId: this.recoveryId })
      const isActive = getAccessInfo.data.status === AccessStatus.work || getAccessInfo.data.status === AccessStatus.repeat
      if (isActive) this.$store.commit('user/setCurrentAccessRepairMethods', getAccessInfo.data.currentRecoveryTypes)
      this.accessRepairData = getAccessInfo.data
      if (created && isActive) this.$emit('openAccessRepair')
    },
    handleUuidCopy (): void {
      copyToClipboard(this.getUserData.uuid).then(() => {
        window.clearTimeout(this.uuidCopiedTimer)
        this.uuidCopiedTimer = window.setTimeout(() => {
          this.uuidCopiedTimer = 0
        }, 1500)
      })
    }
  },
  async created () {
    await Promise.all([this.getAccessInfo(true), this.getKycLevels(), this.getKycData()])
    this.kycDataLoaded = true
    if (this.getUserData.verificationRights.levelName !== 'advanced') this.kycOpen = true
  }
})
